<div [ngSwitch]="!!move?.water?.waterTransferConfirmedByRealEstateAgent">
    <div
        class="u-flex-row u-flex-align-items-center"
        [ngClass]="{ 'u-color-muted': !move?.water?.waterTransferConfirmedByRealEstateAgent }"
    >
        <div *ngSwitchCase="true">
            <div class="u-flex-row u-flex-align-items-center">
                <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                <span class="u-margin-right-half">{{
                    'DETAIL.WATER_METER_READINGS.OK.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard
                }}</span>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <button smvd-ui-button [context]="uiContext.Alt" (click)="openMeterReadings()">
                    {{ 'DETAIL.WATER_METER_READINGS.OK.CTA' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}
                </button>
            </div>
        </div>
        <div *ngSwitchCase="false">
            <div class="u-flex-row u-flex-align-items-center">
                <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'DETAIL.WATER_METER_READINGS.NOK.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}</span>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <button smvd-ui-button [context]="uiContext.Alt" (click)="openMeterReadings()">
                    {{ 'DETAIL.WATER_METER_READINGS.NOK.CTA' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}
                </button>
            </div>
        </div>
    </div>
</div>
