import { Component, Inject, ViewChild } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ClosableModalTemplateComponent } from '@smooved/ui';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';

@Component({
    selector: 'app-water-pick-supplier-modal',
    templateUrl: './water-pick-supplier-modal.component.html',
    styleUrls: ['./water-pick-supplier-modal.component.scss'],
})
export class WaterPickSupplierModalComponent {
    @ViewChild(ClosableModalTemplateComponent, { static: true }) modalRef: ClosableModalTemplateComponent;

    constructor(
        public readonly moveSandbox: MoveSandbox,
        @Inject(MAT_DIALOG_DATA) public waterSuppliers: WaterSupplier[]
    ) {}

    public onSelectWaterSupplier(waterSupplier: WaterSupplier): void {
        this.modalRef.close(waterSupplier);
    }
}
