<app-tri-panel-modal *ngIf="move$ | async as move; else noMove">
    <ng-container modal-header>
        <h5>{{move | userName}}</h5>
    </ng-container>

    <ng-container modal-left>
        <app-transferee-detail
            [move]="move"
            label="MOVE.MOVER_ROLE.LEAVER"
            class="u-margin-bottom"
            (moveChange)="updateMove($event, true)"
        ></app-transferee-detail>
        <app-leaving-address-detail [move]="move" class="u-margin-bottom-half"></app-leaving-address-detail>
        <app-expected-moving-date-detail [move]="move" class="u-margin-bottom"></app-expected-moving-date-detail>

        <h6 class="u-margin-bottom-half u-color-muted">{{ 'MOVE.MOVER_ROLE.TRANSFEREE' | translate }}</h6>
        <div *ngIf="!!move.linkedMove; else noLinkedMove" class="u-flex-column u-flex-align-items-start">
            <span>{{ move.linkedMove.user?.formattedName }}</span>
            <span>{{ move.linkedMove.user?.phoneNumber }}</span>
            <a class="u-link" [href]="move.linkedMove.user?.email | uriSchema: mailTo">{{ move.linkedMove.user?.email }}</a>
            <a class="u-link u-margin-top" (click)="showTransferee(move.linkedMove._id)"
                >{{ 'MOVE.MOVER_ROLE.TRANSFEREE.VIEW_DETAILS' | translate }}</a
            >
        </div>
        <ng-template #noLinkedMove>
            <span class="u-color-muted u-opacity-50"
                >{{ 'DETAIL.LEAVER_UNKNOWN' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard}} }}</span
            >
        </ng-template>
    </ng-container>

    <ng-container modal-center>
        <app-menu>
            <app-menu-item [readonly]="true">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.SERVICES' | translate}}</app-menu-item>

            <app-menu-item [size]="menuItemSizes.Small" (click)="changeTab(tabs.energy)" [active]="activeTab === tabs.energy" [level]="1">
                {{ 'DETAIL.TRANSFER_ENERGY' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard}}
                <sup *ngIf="hasEnergyTodo">
                    <app-icon icon="error" class="u-color-danger"></app-icon>
                </sup>
            </app-menu-item>
            <app-menu-item [size]="menuItemSizes.Small" (click)="changeTab(tabs.water)" [active]="activeTab === tabs.water" [level]="1">
                {{ 'DETAIL.TRANSFER_WATER' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard}}
                <sup *ngIf="hasWaterTodo">
                    <app-icon icon="error" class="u-color-danger"></app-icon>
                </sup>
            </app-menu-item>

            <app-menu-item (click)="changeTab(tabs.contactLogs)" [active]="activeTab === tabs.contactLogs">
                {{'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.MOBILE' | translate}}
            </app-menu-item>

            <app-menu-item (click)="changeTab(tabs.nps)" [active]="activeTab === tabs.nps">
                {{ 'DETAIL.NPS.TITLE' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard}}
            </app-menu-item>
        </app-menu>
    </ng-container>

    <ng-container modal-right>
        <ng-container *ngIf="activeTab === tabs.energy">
            <h5 class="u-margin-bottom-half">{{ 'ENERGY.LABEL' | translate }}</h5>
            <app-move-track-energy></app-move-track-energy>
        </ng-container>
        <ng-container *ngIf="activeTab === tabs.water">
            <h5 class="u-margin-bottom-half">{{ 'WATER.TRANSFER' | translate }}</h5>
            <app-move-leaver-detail-water (showTransfereeWater)="showTransferee(DbUtils.getStringId(move.linkedMove), MoveDetailTab.water)">
            </app-move-leaver-detail-water>
        </ng-container>
        <ng-container *ngIf="activeTab === tabs.contactLogs">
            <h6 class="u-margin-bottom-half">{{'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.DESKTOP' | translate}}</h6>
            <app-contact-logs-overview [move]="move"></app-contact-logs-overview>
        </ng-container>
        <ng-container *ngIf="activeTab === tabs.nps">
            <h6 class="u-margin-bottom-half">{{'DETAIL.NPS.TITLE' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard}}</h6>
            <app-move-nps-detail [move]="move"></app-move-nps-detail>
            <app-gift-track-and-trace
                *ngIf="(move.orders | orderGift) as order; else sendGiftBox"
                [order]="order"
                class="u-margin-top-triple u-display-block"
            ></app-gift-track-and-trace>
            <ng-template #sendGiftBox>
                <app-send-gift-box
                    *hasFeatureAccess="[featureScope.Gift]"
                    (reload)="reload()"
                    [move]="move"
                    class="u-margin-top-triple u-display-block"
                ></app-send-gift-box>
            </ng-template>
        </ng-container>
    </ng-container>
</app-tri-panel-modal>

<ng-template #noMove>
    <div class="u-w60vw u-flex-column u-flex-align-items-start">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-template>
