import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';
import { environment } from '@environments/environment';
import { HttpUtils, StringUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { WaterInfoResponse } from '../interfaces/water-info-response';
import { WaterTransferAssetType } from '@app/water/enums/water-transfer-asset-type.enum';

@Injectable({
    providedIn: 'root',
})
export class WaterService {
    public static readonly baseUri: string = `${environment.apiUri}/water`;

    constructor(private httpClient: HttpClient) {}

    public getWaterInfoByZipCode(zipCode: string): Observable<WaterInfoResponse[]> {
        const httpParams: HttpParams = new HttpParams().set('zipCode', zipCode);

        return this.httpClient.get<WaterInfoResponse[]>(WaterService.baseUri, {
            params: httpParams,
        });
    }

    public getWaterSuppliers(): Observable<WaterSupplier[]> {
        return this.httpClient.get<WaterSupplier[]>(`${WaterService.baseUri}/water-suppliers`);
    }

    public confirmWaterProcess(transactionId: string): Observable<Move> {
        return this.httpClient.post<Move>(
            StringUtils.parseUri(`${WaterService.baseUri}/transaction/:transactionId/confirm`, { transactionId }),
            null
        );
    }

    public setWaterTransferNeeded(transactionId: string, transferNeeded: boolean): Observable<any> {
        return this.httpClient.patch<Move>(`${WaterService.baseUri}/transaction/${transactionId}/transfer/${transferNeeded ? 1 : 0}`, null);
    }

    public updateWaterTransferAssets(
        transactionId: string,
        assetKeysToRemove: string[],
        files: File[],
        waterTransferAssetType?: WaterTransferAssetType
    ): Observable<Move> {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('assetKeysToRemove', assetKeysToRemove.join(';'));

        return this.httpClient.post<Move>(`${WaterService.baseUri}/transaction/${transactionId}/water-transfer-assets`, formData, {
            params: HttpUtils.buildQueryParameters({ waterTransferAssetType }),
        });
    }

    public generateWaterTransferDocument(transactionId: string): Observable<Move> {
        return this.httpClient.post<Move>(`${WaterService.baseUri}/transaction/${transactionId}/generate-water-transfer-document`, null);
    }

    public setWaterTransferAssetTypes(
        transactionId: string,
        assetTypeMapping: {
            [id: string]: WaterTransferAssetType;
        }
    ): Observable<Move> {
        return this.httpClient.post<Move>(`${WaterService.baseUri}/transaction/${transactionId}/water-transfer-asset-types`, {
            assetTypeMapping,
        });
    }

    public getWaterTransferAsset(transactionId: string, assetId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${WaterService.baseUri}/transaction/${transactionId}/water-transfer-asset/${assetId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }
}
