import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlNames } from '@app/admin/components/real-estate-group-appointments-filter/real-estate-group-appointments-filter.constants';
import { placeholders, WaterDetailFields, waterSupplierOptionsFactory } from '@app/admin/modals/water-details/water-details.constants';
import { FilePreviewModal } from '@app/energy/modals/file-preview/file-preview.modal';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveDetailWaterSectionService } from '@app/real-estate-agent/components/move-detail-water-section/move-detail-water-section.service';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { WaterTransferAssetType } from '@app/water/enums/water-transfer-asset-type.enum';
import { Water } from '@app/water/interfaces/water';
import { WaterSandbox } from '@app/water/sandboxes/water.sandbox';
import { WaterService } from '@app/water/services/water.service';
import {
    ALLOWED_MIME_TYPES,
    AnalyticsEventsEnum,
    AnalyticsService,
    ArrayUtils,
    DbUtils,
    EnergyMetersState,
    FileUtils,
    MAX_FILE_SIZE,
    Mimetypes,
    ObjectUtils,
    RxjsComponent,
    WaterMeterState,
} from '@smooved/core';
import {
    ButtonAppearance,
    ButtonSize,
    ClosableModalTemplateComponent,
    FormUtils,
    gasControlNames,
    ModalSandbox,
    NotificationSandbox,
    SvgIllustration,
    UiAlignment,
    UiContext,
    UiSize,
} from '@smooved/ui';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, combineLatest, Observable, of, startWith, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WaterPickSupplierModalComponent } from '@app/real-estate-agent/modals/water-pick-supplier/water-pick-supplier-modal.component';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';

@Component({
    selector: 'app-move-detail-complete-water-data',
    templateUrl: './move-detail-water-data-modal.component.html',
    styleUrls: ['./move-detail-water-data-modal.component.scss'],
})
export class MoveDetailWaterDataModal extends RxjsComponent implements OnInit, AfterViewInit {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public uploader: FileUploader;

    public move$ = this.moveSandbox.move$;
    public hasCollectiveWaterMeter$ = this.move$.pipe(map((move) => move.waterCollectiveMeter === true));
    public waterTransferLocked$ = this.move$.pipe(
        map((move: Move) => {
            return move?.waterDocumentsMovingAddressByAdmin;
        })
    );
    public waterTransferDocumentAvailable$ = this.moveSandbox.moveOnce$.pipe(
        map((move: Move) =>
            move?.water?.waterTransferAssets.some((asset) => {
                return asset.waterTransferAssetType === WaterTransferAssetType.TransferDocument;
            })
        )
    );

    public canConfirmMeters$: Observable<boolean>;
    private filesChangedSubject = new Subject<void>();
    private readonly isConfirmedSubject = new BehaviorSubject<boolean>(false);

    public readonly buttonAppearance = ButtonAppearance;
    public readonly waterDetailFields = WaterDetailFields;
    public readonly placeholders = placeholders;
    public readonly context = UiContext;
    public readonly formControlNames = FormControlNames;
    protected readonly uiSize = UiSize;
    protected readonly UiAlignment = UiAlignment;
    protected readonly svgIllustration = SvgIllustration;
    protected readonly fields = gasControlNames;
    protected readonly EnergyMetersState = EnergyMetersState;
    protected readonly buttonSize = ButtonSize;
    protected readonly uiContext = UiContext;
    public loading = false;

    public form: FormGroup;

    public readonly isMobile$ = this.uiSandbox.upToAndIncludingPhoneLandscape$;

    constructor(
        public moveSandbox: MoveSandbox,
        private readonly waterSandbox: WaterSandbox,
        private readonly waterService: WaterService,
        private fb: FormBuilder,
        private readonly analyticsService: AnalyticsService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly uiSandbox: AppUiSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly http: HttpClient,
        private readonly moveDetailWaterSectionService: MoveDetailWaterSectionService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.moveOnce$
            .pipe(
                tap((move) => {
                    this.form = this.fb.group({
                        [WaterDetailFields.MovingDate]: [move.movingDate, Validators.required],
                        [WaterDetailFields.NationalRegistrationNumber]: [move.water?.nationalRegistrationNumber],
                        [WaterDetailFields.ClientNumber]: [move.water?.clientNumber],
                        [WaterDetailFields.WaterSupplier]: [DbUtils.getStringId(move.water?.waterSupplier)],
                        [WaterDetailFields.MeterNumber]: [move.water?.meterNumber],
                        [WaterDetailFields.MeterReading]: [move.water?.meterReading],
                    });

                    if (move?.track?.waterMeterReadings?.waterMetersState === WaterMeterState.Processed) {
                        this.form.get(WaterDetailFields.MovingDate).disable();
                        this.form.get(WaterDetailFields.NationalRegistrationNumber).disable();
                        this.form.get(WaterDetailFields.ClientNumber).disable();
                        this.form.get(WaterDetailFields.WaterSupplier).disable();
                        this.form.get(WaterDetailFields.MeterNumber).disable();
                        this.form.get(WaterDetailFields.MeterReading).disable();
                    }

                    this.isConfirmedSubject.next(move?.track?.waterMeterReadings?.waterMetersState === WaterMeterState.Confirmed);
                })
            )
            .subscribe();
        this.uploader = FileUtils.getFileUploader();
        this.handleAssetsInit();
        this.canConfirmMeters$ = combineLatest([
            this.form.valueChanges.pipe(startWith(this.form.value)),
            this.filesChangedSubject.asObservable(),
            this.isConfirmedSubject.asObservable(),
        ]).pipe(
            debounceTime(0),
            map(([{ meterNumber, meterReading }, _, isConfirmed]) => {
                return ((!!meterReading && !!meterNumber) || !ArrayUtils.isEmpty(this.uploader.queue)) && !isConfirmed;
            })
        );

        this.moveDetailWaterSectionService.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => (this.loading = loading));
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.isConfirmedSubject.next(false));
    }

    public ngAfterViewInit(): void {
        this.filesChangedSubject.next();
    }

    private handleAssetsInit(): void {
        this.moveSandbox.moveOnce$
            .pipe(
                map((move) => {
                    const uploadedAssets = [...(move.water?.waterTransferAssets ?? [])] as unknown as File[];
                    this.uploader.addToQueue(uploadedAssets);
                })
            )
            .subscribe();
    }

    public onFileSelected(list: FileList): void {
        for (const item of Array.from(list)) {
            if (item.size > FileUtils.convertMegaBytesToBytes(MAX_FILE_SIZE)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.SIZE', { maxFileSize: MAX_FILE_SIZE });
                break;
            }

            if (!ALLOWED_MIME_TYPES.includes(item.type as Mimetypes)) {
                this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.TYPE', {
                    allowedTypes: ALLOWED_MIME_TYPES.map((mime) => ArrayUtils.last(mime.split('/'))).join(', '),
                });
                break;
            }
        }
        this.form.markAsDirty();
        this.isConfirmedSubject.next(false);
        this.filesChangedSubject.next();
    }

    private uploadWaterAssets(move: Move): Observable<Move> {
        const filesToAdd: File[] = FileUtils.getFilesToAdd(move, this.uploader);
        const assetKeysToRemove: string[] = FileUtils.getAssetKeysToRemove(move, this.uploader);

        if (!ArrayUtils.isEmpty(filesToAdd) || !ArrayUtils.isEmpty(assetKeysToRemove)) {
            this.uiSandbox.showLoadingOverlay();
            return this.waterService.updateWaterTransferAssets(DbUtils.getStringId(move), assetKeysToRemove, filesToAdd);
        }

        return of(move);
    }

    public toggleWaterTransferNeeded(value: boolean): void {
        this.loading = true;
        this.moveSandbox.patchProperty('waterCollectiveMeter', !value, true, (move) => {
            this.moveSandbox.setLatestMoveState(move);
            this.loading = false;
        });
    }

    public confirmAndSaveWaterData(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;

        const saveWater$ = new Observable<string>((sub) => {
            this.saveWaterData((moveId) => sub.next(moveId));
        });

        const saveAndConfirm = saveWater$.pipe(
            switchMap((moveId) => this.moveDetailWaterSectionService.confirmHandler(moveId)),
            tap((move) => {
                this.moveSandbox.setMoveState(move);
                this.closableModalComponent.close();
            })
        );

        this.moveDetailWaterSectionService
            .confirmModal()
            .pipe(
                switchMap((confirm) => {
                    if (confirm && !!this.form.get(WaterDetailFields.WaterSupplier).value) {
                        return saveAndConfirm;
                    } else if (confirm && !this.form.get(WaterDetailFields.WaterSupplier).value) {
                        this.moveDetailWaterSectionService.openPickWaterSupplierModal(this.onPickWaterSupplier(saveAndConfirm));
                    }
                    return of(null);
                })
            )
            .subscribe();
    }

    private onPickWaterSupplier(confirmCb: Observable<Move>) {
        return (waterSupplier: WaterSupplier): void => {
            this.form.get(WaterDetailFields.WaterSupplier).setValue(DbUtils.getStringId(waterSupplier));
            this.form.get(WaterDetailFields.WaterSupplier).markAsDirty();
            confirmCb.subscribe();
        };
    }

    public saveWaterData(onCompleteSave?: (moveId: string) => void): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;
        this.loading = true;

        const data = this.form.value;

        this.moveSandbox.moveOnce$
            .pipe(
                switchMap((move) => this.uploadWaterAssets(move)),
                map((move) => {
                    const touchedValues = FormUtils.getTouchedValues(this.form);
                    const payload = {
                        movingDate: touchedValues.movingDate,
                        water: {
                            nationalRegistrationNumber: touchedValues.nationalRegistrationNumber,
                            clientNumber: touchedValues.clientNumber,
                            waterSupplier: touchedValues.waterSupplier,
                            meterReading: touchedValues.meterReading,
                            meterNumber: touchedValues.meterNumber,
                            waterTransferConfirmedByRealEstateAgent: false,
                        } as Water,
                    };
                    ObjectUtils.removeEmpty(payload);

                    this.moveSandbox.patch({
                        moveId: DbUtils.getStringId(move),
                        payload,
                        withNotification: false,
                        dialogIdToClose: null,
                        callback: (move) => {
                            this.analyticsService.sendEvent(AnalyticsEventsEnum.ReaWaterAddExtraDataSubmit, {
                                moveId: DbUtils.getStringId(move),
                            });
                            this.moveSandbox.setMoveState(move);
                            this.loading = false;
                            this.uiSandbox.hideLoadingOverlay();
                            !!onCompleteSave ? onCompleteSave(DbUtils.getStringId(move)) : this.closableModalComponent.close();
                        },
                    });
                })
            )
            .subscribe();
    }

    public openFileDetail(file: FileItem): void {
        /**
         *  file.rawFile can be two types:
         *  1 - blob, when it's coming from uploader (selected in input[type=file]);
         *  2 - FileInput, when file is already in Database. In this case, fileInput is set on rawFile of FileItem;
         *
         * */

        const handleFileDetail = (moveId: string) => {
            const fileType: 'FileInput' | 'Blob' = !!(file.file.rawFile as unknown as FileInput)?.location ? 'FileInput' : 'Blob';

            if (fileType === 'FileInput') {
                const fileInput = file.file.rawFile as unknown as FileInput;
                this.http
                    .get(`${WaterService.baseUri}/transaction/${moveId}/water-transfer-asset/${DbUtils.getStringId(fileInput)}`, {
                        responseType: 'arraybuffer',
                    })
                    .pipe(
                        map((data) => {
                            const blob = new Blob([data], { type: 'application/pdf' });
                            FileUtils.downloadAsset(blob, fileInput.name);
                        })
                    )
                    .subscribe();
                return;
            }

            if (fileType === 'Blob') {
                const fileInput = file.file.rawFile as File;
                if (fileInput.type === Mimetypes.Pdf) {
                    FileUtils.downloadAsset(fileInput, file.file.name);
                    return;
                }
            }

            /** If not a PDF, open modal to preview image */
            this.modalSandbox.openModal(FilePreviewModal, { data: file }, null, FilePreviewModal, { data: file }, null);
        };

        this.moveSandbox.idOnce$.subscribe(handleFileDetail);
    }

    public removeAsset(item: FileItem): void {
        this.uploader.removeFromQueue(item);
        this.form.markAsDirty();
        this.filesChangedSubject.next();
    }

    protected readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;
    protected readonly WaterMeterState = WaterMeterState;
}
