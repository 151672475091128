<app-closable-modal-template maxWidth="550px">
    <ng-container modal-header>
        <h1 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-20">
            {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.SUPPLIER.LABEL' | translate }}
        </h1>
        <p class="u-font-size-default u-color-gray-dark">{{ (moveSandbox.move$ | async)?.user?.formattedMovingAddress }}</p>
    </ng-container>

    <p class="u-margin-bottom u-color-gray-dark">{{ 'MOVE.SERVICE_ACTIONS.WATER.SUPPLIER.MULTIPLE' | translate }}</p>

    <div
        *ngFor="let waterSupplier of waterSuppliers"
        (click)="onSelectWaterSupplier(waterSupplier)"
        class="u-flex-row u-flex-align-items-center u-padding u-cursor-pointer u-color-gray-ultradark __water-supplier"
    >
        {{ waterSupplier?.name }}
    </div>
</app-closable-modal-template>
