import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-move-leaver-detail-water',
    template: ` <a class="u-link" (click)="showTransfereeWater.emit()">{{ 'MOVE.SERVICE_ACTIONS.WATER_CTA' | translate }}</a> `,
    styles: [':host {display:block}'],
})
export class MoveLeaverDetailWaterComponent {
    @Output() public showTransfereeWater: EventEmitter<void> = new EventEmitter<void>();
}
