import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MoveData } from '@app/move/classes/move-data.class';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalData } from '@app/real-estate-agent/interfaces/modal-data.interfaces';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { MoveTodo } from '@app/wizard/move/interfaces/move-todo';
import { DbUtils, FeatureScope, I18nKeyType } from '@smooved/core';
import { MenuItemSize, TriPanelModalComponent, UriSchemaType } from '@smooved/ui';
import { defaultActive, energyTodoList, MoveLeaverDetailTab, waterTodoList } from './move-leaver-detail.constants';
import { MoveDetailTab } from '@app/real-estate-agent/modals/move-detail/move-detail.constants';

@Component({
    selector: 'app-move-leaver-detail',
    templateUrl: './move-leaver-detail.modal.html',
    styleUrls: ['./move-leaver-detail.modal.scss'],
})
export class MoveLeaverDetailModalComponent extends MoveData implements OnInit {
    @ViewChild(TriPanelModalComponent) private triPanelModal: TriPanelModalComponent;

    public activeTab = defaultActive;
    public hasEnergyTodo = false;
    public hasWaterTodo = false;
    public readonly tabs = MoveLeaverDetailTab;
    public readonly mailTo = UriSchemaType.MailTo;
    public readonly featureScope = FeatureScope;
    public readonly i18nKeys = { ...I18nKeyType, ...AppI18nKeyType };
    public readonly menuItemSizes = MenuItemSize;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        @Inject(MAT_DIALOG_DATA) public data: ModalData
    ) {
        super(moveSandbox, data.patchedSubject);
    }

    public ngOnInit(): void {
        this.fetch(this.data.id, (move) => {
            this.hasEnergyTodo = this.hasServiceTodo(move.todosForRealEstateAgent, energyTodoList);
            this.hasWaterTodo = this.hasServiceTodo(move.todosForRealEstateAgent, waterTodoList);
        });
    }

    public changeTab(tab: MoveLeaverDetailTab): void {
        this.activeTab = tab;
    }

    public showTransferee(moveId: string, activeTab?: MoveDetailTab): void {
        this.triPanelModal.close({ openMove: moveId, activeTab: activeTab });
    }

    private hasServiceTodo(todoList: MoveTodo[], serviceTodos: TodoType[]): boolean {
        return todoList?.some((moveTodo): boolean => serviceTodos.includes(moveTodo.todo));
    }

    protected readonly DbUtils = DbUtils;
    protected readonly MoveDetailTab = MoveDetailTab;
}
