import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { SendGiftModal } from '@app/gift/modals/send-gift/send-gift.modal';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { ServiceStatus } from '@app/move/enums/service-status.enum';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { defaultSortField } from '@app/real-estate-agent/constants/default-table.constants';
import { MoveDetailWaterDataModal } from '@app/real-estate-agent/modals/move-detail-water-data/move-detail-water-data-modal.component';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { InterviewResult } from '@app/surveys/interfaces/data/interview-result';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { RealEstateAgentMovesDataSource } from '@app/wizard/move/data-source/moves/real-estate-agent-moves.data-source';
import { DbUtils, I18nKeyService, ObjectUtils, SimpleChangesUtils, SortDirection } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, UiIconAppearance, UiIconSize, UiPlacement } from '@smooved/ui';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { RealEstateAgentDashboardSandbox } from '../../sandboxes/real-estate-agent-dashboard.sandbox';
import { TableViews, maxPower, powerBarsMap, scoreIgnoreConfirmI18n, tableColumnsMap } from './dashboard-table.constants';

@Component({
    selector: 'app-dashboard-table',
    templateUrl: './dashboard-table.component.html',
    styleUrls: ['./dashboard-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardTableComponent extends CoreTableComponent implements OnInit, OnChanges {
    @Input() public dataSource: RealEstateAgentMovesDataSource;
    @Input() public view: TableViews = MoverRole.Transferee;

    @Output() public rowClick: EventEmitter<Move> = new EventEmitter<Move>();
    @Output() public reload: EventEmitter<void> = new EventEmitter<void>();

    public readonly defaultSortField = defaultSortField;
    public readonly defaultSortDirection = SortDirection.Desc;
    public readonly tooltipPlacement = UiPlacement.LeftEnd;
    public readonly moveUtils = MoveUtils;
    public readonly moverRole = MoverRole;

    public columns: string[];
    public isTabletPortraitUp: boolean;
    public screenSize: string;
    public maxPower = maxPower;
    public powerBarsMap = powerBarsMap;
    public buttonAppearance = ButtonAppearance;
    public uiIconAppearance = UiIconAppearance;
    public uiIconSize = UiIconSize;
    public serviceStatus = ServiceStatus;
    public bars = Array(maxPower);

    constructor(
        public readonly moveSandbox: MoveSandbox,
        public readonly realEstateAgentDashboardSandbox: RealEstateAgentDashboardSandbox,
        public readonly uiSandbox: AppUiSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly i18nKeyService: I18nKeyService,
        private readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.uiSandbox.screenSize$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((screenSize: string) => {
            this.screenSize = screenSize;
            this.handleScreenSizeChange(this.screenSize);
        });
    }

    public ngOnChanges({ view }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(view)) {
            this.handleScreenSizeChange(this.screenSize);
            this.sort.sort({ id: defaultSortField, start: '', disableClear: false });
            this.sort.direction = SortDirection.Desc;
        }
    }

    public getLives(clusterLevel: number): number {
        return maxPower - Math.min(maxPower, clusterLevel || 0);
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public sendGift(event: MouseEvent, move: Move): void {
        event.stopPropagation();
        const data = {
            data: move._id,
        };
        const onClose = (): void => this.reload.emit();
        this.modalSandbox.openModal(SendGiftModal, data, onClose, SendGiftModal, data, onClose);
    }

    public confirmIgnoreGift(event: MouseEvent, move: Move): void {
        event.stopPropagation();
        const data = { data: this.i18nKeyService.instant(scoreIgnoreConfirmI18n, appI18nKeyTypes.RealEstateAgentDashboard) };
        const onClose = (confirm: boolean): void => this.ignoreGift(confirm, move);
        this.modalSandbox.openConfirmModal(data, onClose, data, onClose);
    }

    public openEnergyMeterInformation(event: MouseEvent, move: Move): void {
        event.stopImmediatePropagation();
        this.moveSandbox.setMoveState(move);
        const onCloseModalCallback = () => this.moveSandbox.clearMoveState();
        this.realEstateAgentModalsSandbox.openMeterInfoModal(onCloseModalCallback);
    }

    public openWaterMeterInformation(event: MouseEvent, move: Move): void {
        event.stopImmediatePropagation();
        this.moveSandbox.setMoveState(move);
        const onCloseModalCallback = () => this.moveSandbox.clearMoveState();
        this.modalSandbox.openModal(
            MoveDetailWaterDataModal,
            {},
            onCloseModalCallback,
            MoveDetailWaterDataModal,
            null,
            onCloseModalCallback
        );
    }

    private ignoreGift(confirm: boolean, move: Move): void {
        if (!confirm) return;
        const nps: Partial<InterviewResult> = { ignoreGift: true };
        const payload: Partial<Move> = {
            surveys: {
                nps: nps as InterviewResult,
            },
        };
        ObjectUtils.removeEmpty(payload);
        this.moveSandbox.patch({ moveId: DbUtils.getStringId(move), payload, withNotification: true });
    }

    private handleScreenSizeChange = (screenSize: string): void => {
        this.columns = tableColumnsMap[this.view][screenSize];
    };
    protected readonly TodoType = TodoType;
}
