<div [ngSwitch]="!!move?.moveStates?.waterDocumentsMovingAddressByAdmin">
    <div [ngClass]="{ 'u-color-muted': !move?.moveStates?.waterDocumentsMovingAddressByAdmin }">
        <ng-container *ngSwitchCase="true">
            <div class="u-flex-row u-flex-align-items-center">
                <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                <span class="u-margin-right-half">{{
                    'DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard
                }}</span>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <button smvd-ui-button (click)="uncheck()" [context]="uiContext.Alt">
                    {{ 'UNCHECK' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
            <div class="u-flex-row u-flex-align-items-center">
                <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}</span>
            </div>
            <div class="u-margin-top-half u-margin-left-double">
                <button
                    smvd-ui-button
                    id="check-button"
                    [disabled]="
                        move?.track?.waterMeterReadings.waterMetersState <= WaterMeterState.Completed ||
                        !move?.moveStates?.waterTransferDocuments
                    "
                    (click)="check()"
                    [context]="uiContext.Alt"
                >
                    {{ 'CHECK' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</div>
