import { HttpResponse } from '@angular/common/http';
import { ArrayUtils, Asset, Mimetypes } from '@smooved/core';
import { FileUploader } from 'ng2-file-upload';
import { Move } from '@app/move/interfaces/move';

export interface OpenAssetOptions {
    response: HttpResponse<Blob | ArrayBuffer>;
    type: 'blob' | 'arraybuffer';
    mimeType?: Mimetypes;
    fileName?: string;
}

export const ALLOWED_MIME_TYPES = [Mimetypes.Jpeg, Mimetypes.Png, Mimetypes.Pdf];
export const ALLOWED_FILE_TYPES = ['image', 'pdf'];
export const MAX_FILE_SIZE = 10; // Max size in MB

export class FileUtils {
    public static mimetypeFilter(...mimetypes: string[]) {
        return (_, file, callback): any => {
            const mimeTypes = mimetypes.map((m) => m.toLowerCase());

            if (!mimeTypes.includes(file.mimetype)) {
                return callback(new Error('ERROR.INVALID_FILE_FORMAT'), false);
            }

            callback(null, true);
        };
    }

    /**
     * This method will get asset from server and open it to user
     */
    public static openAsset({ response, mimeType, type, fileName }: OpenAssetOptions): void {
        const blob = new Blob([response.body], { type: mimeType });
        FileUtils.downloadAsset(blob, fileName, response);
    }

    public static downloadAsset(blob: Blob, fileName: string, response?: HttpResponse<Blob | ArrayBuffer>): void {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = fileName || response.headers.get('Filename');
        anchor.target = '_blank';
        anchor.click();
    }

    public static convertBytesToMegaBytes(bytes: number, decimalNumbers: number = 2): string {
        return (bytes / (1024 * 1024)).toFixed(decimalNumbers);
    }

    public static convertMegaBytesToBytes(megaBytes: number): number {
        return megaBytes * (1024 * 1024);
    }

    public static getFileUploader(): FileUploader {
        return new FileUploader({
            url: '',
            disableMultipart: true,
            formatDataFunctionIsAsync: true,
            allowedFileType: ALLOWED_FILE_TYPES,
            allowedMimeType: ALLOWED_MIME_TYPES,
            maxFileSize: FileUtils.convertMegaBytesToBytes(MAX_FILE_SIZE),
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date(),
                    });
                });
            },
        });
    }

    public static getAssetKeysToRemove(move: Move, uploader: FileUploader): string[] {
        const existingAssets = [...(move?.water?.waterTransferAssets ?? [])];
        return existingAssets
            ?.filter((asset) => !uploader.queue?.map((item) => item._file).some((file) => (file as unknown as Asset).key === asset?.key))
            .map((asset) => asset.key);
    }

    public static getFilesToAdd(move: Move, uploader: FileUploader): File[] {
        const existingAssets = [...(move?.water?.waterTransferAssets ?? [])];
        return uploader.queue
            .map((item) => item._file)
            .filter((file) => !existingAssets.some((asset) => asset.key === (file as unknown as Asset).key));
    }
}
