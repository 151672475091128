import { Component, Input } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ArrayUtils, Asset, Mimetypes } from '@smooved/core';
import { SvgIllustration, UiContext, UiIcon, UiIconSize } from '@smooved/ui';
import { WaterTransferAssetType } from '@app/water/enums/water-transfer-asset-type.enum';

@Component({
    selector: 'app-water-meter-reading-details',
    templateUrl: 'water-meter-reading-details.component.html',
    styleUrls: ['./water-meter-reading-details.component.scss'],
})
export class WaterMeterReadingDetailsComponent {
    @Input() public move: Move;

    public readonly UiIconSize = UiIconSize;
    public readonly UiIcon = UiIcon;
    public readonly uiIcon = UiIcon;
    public readonly ArrayUtils = ArrayUtils;
    protected readonly WaterTransferAssetType = WaterTransferAssetType;
    protected readonly context = UiContext;
    protected readonly Mimetypes = Mimetypes;
    protected readonly svgIllustration = SvgIllustration;
    protected readonly SvgIllustration = SvgIllustration;

    constructor(private readonly moveSandbox: MoveSandbox) {}
}
