import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { WaterDocumentMovingAddressModal } from '@app/water/modals/water-document-moving-address/water-document-moving-address.modal';
import { ModalSandbox, Svg, UiContext, UiSize } from '@smooved/ui';
import { WaterProcessAttachmentsModal } from '@app/water/modals/water-process-attachments/water-process-attachments.modal';
import { WaterDetailsModalComponent } from '@app/admin/modals/water-details/water-details.modal';
import { AddressUtils } from '@smooved/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-water-transfer-documents',
    templateUrl: 'water-transfer-documents.component.html',
})
export class WaterTransferDocumentsComponent extends BaseServiceActionComponent {
    @Input() public move: Move;
    @Output() public moveUpdated: EventEmitter<Move> = new EventEmitter<Move>();

    public emptySvg = Svg.Empty;
    public readonly uiContext = UiContext;
    public readonly appI18nKeyType = AppI18nKeyType;

    constructor(
        private readonly modalSandbox: ModalSandbox,
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef,
        protected readonly translateService: TranslateService
    ) {
        super(cdr, moveSandbox);
    }

    public showWaterDocuments(): void {
        const data: ModalDataMove = {
            move: this.move,
        };
        const input = { move: this.move };
        const header = this.translateService.instant('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.LABEL') as string;
        this.modalSandbox
            .openClosableModal({
                component: WaterProcessAttachmentsModal,
                config: {
                    input,
                    header,
                    subheader: AddressUtils.buildFormattedAddress(this.move.user.movingAddress),
                    size: UiSize.Md,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveUpdated.emit(move);
            });
    }
}
