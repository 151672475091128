<div [ngSwitch]="!!move?.moveStates?.waterTransferDocuments">
    <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.moveStates?.waterTransferDocuments }">
        <div *ngSwitchCase="true">
            <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
            <span class="u-margin-right-half">{{
                'DETAIL.WATER_TRANSFER_DOCUMENTS.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard
            }}</span>
        </div>
        <div *ngSwitchCase="false">
            <div class="u-flex-row u-flex-align-items-center">
                <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                <span>{{ 'DETAIL.WATER_TRANSFER_DOCUMENTS.LABEL' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}</span>
            </div>
        </div>
    </div>
    <div class="u-margin-top-half u-margin-left-double">
        <button smvd-ui-button (click)="showWaterDocuments()" [context]="uiContext.Alt">
            {{ 'DETAIL.WATER_TRANSFER_DOCUMENTS.CTA' | i18nKeyTranslate: appI18nKeyType.AdminDashboard }}
        </button>
    </div>
</div>
