import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { WaterMeterState } from '@smooved/core';
import { MeterStatePillBaseComponent } from '../meter-state-pill-base/meter-state-pill-base.component';

@Component({
    selector: 'smvd-ui-water-meter-state-pill',
    templateUrl: '../meter-state-pill-base/meter-state-pill-base.component.html',
    styleUrls: ['../meter-state-pill-base/meter-state-pill-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterMeterStatePillComponent extends MeterStatePillBaseComponent implements OnInit, OnChanges {
    public stateClass: string = `__meter-state--${WaterMeterState.Incomplete}`;

    private readonly defaultMetersStateKey = WaterMeterState[WaterMeterState.Incomplete];

    override setAppearanceAndState(): void {
        this.stateKey = WaterMeterState[this.meterState] ? WaterMeterState[this.meterState] : WaterMeterState[WaterMeterState.Incomplete];
        this.stateClass =
            this.todo && this.meterState === WaterMeterState.Incomplete
                ? '__meter-state-todo'
                : `__meter-state--${this.stateKey.toLowerCase()}`;
        this.setSvgIcon();
    }
}
