import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { RealEstateAgentProvidersModule } from '@app/real-estate-agent/real-estate-agent-providers.module';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { WaterSandbox } from '@app/water/sandboxes/water.sandbox';
import { ArrayUtils, I18nKeyService } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { BehaviorSubject, catchError, finalize, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WaterPickSupplierModalComponent } from '@app/real-estate-agent/modals/water-pick-supplier/water-pick-supplier-modal.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';

@Injectable({
    providedIn: RealEstateAgentProvidersModule,
})
export class MoveDetailWaterSectionService {
    private loadingSubject = new BehaviorSubject(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(
        private readonly i18nKeyService: I18nKeyService,
        private readonly modalSandbox: ModalSandbox,
        private readonly waterSandbox: WaterSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public confirmModal(): Observable<boolean> {
        return new Observable((s) => {
            const cb = (confirm: boolean) => {
                s.next(confirm);
                s.complete();
            };
            const config = {
                header: this.i18nKeyService.instant(
                    'DETAIL.WATER.TRANSFER.COMPLETED.CONFIRM_MODAL.HEADER',
                    appI18nKeyTypes.RealEstateAgentDashboard
                ),
                question: this.i18nKeyService.instant(
                    'DETAIL.WATER.TRANSFER.COMPLETED.CONFIRM_MODAL.INFO',
                    appI18nKeyTypes.RealEstateAgentDashboard
                ),
            };
            this.modalSandbox.openConfirmModal(config, cb, config, cb);
        });
    }

    public confirmHandler(moveId: string): Observable<Move> {
        if (!moveId) return of(null);
        this.loadingSubject.next(true);
        return this.waterSandbox.confirmTransaction(moveId).pipe(
            catchError((_) => of(null)),
            finalize(() => this.loadingSubject.next(false))
        );
    }

    public openPickWaterSupplierModal(onPickWaterSupplier: (waterSupplier: WaterSupplier) => void): void {
        this.moveSandbox.moveOnce$
            .pipe(
                switchMap((move) => this.waterSandbox.getWaterInfoByZipCode(move.user?.movingAddress?.zipCode)),
                tap((waterResponses) => {
                    const uniqueWaterSuppliers = ArrayUtils.uniqueByProperty(
                        waterResponses.map((waterResponse) => waterResponse.waterSupplier),
                        '_id'
                    );
                    if (ArrayUtils.getLength(uniqueWaterSuppliers) > 1) {
                        this.modalSandbox.openModal(
                            WaterPickSupplierModalComponent,
                            { data: uniqueWaterSuppliers },
                            onPickWaterSupplier,
                            WaterPickSupplierModalComponent,
                            { data: uniqueWaterSuppliers },
                            onPickWaterSupplier
                        );
                    }
                    if (ArrayUtils.getLength(uniqueWaterSuppliers) === 1) {
                        onPickWaterSupplier(ArrayUtils.first(uniqueWaterSuppliers));
                    }
                })
            )
            .subscribe();
    }
}
