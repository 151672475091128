import { Injectable } from '@angular/core';
import { Service } from '@app/move/enums/service.enum';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { Move } from '@app/move/interfaces/move';
import { ModalClosingData, ModalSandbox } from '@smooved/ui';
import { ModalData } from '../interfaces/modal-data.interfaces';
import { MobileMoveDetailInsurancesModalComponent } from '../modals/mobile-move-detail-insurances/mobile-move-detail-insurances.component';
import { MobileMoveLeaverOverviewComponent } from '../modals/mobile-move-leaver-overview/mobile-move-leaver-overview.component';
import { MobileMoveOverviewComponent } from '../modals/mobile-move-overview/mobile-move-overview.component';
import { MoveDetailModalComponent } from '../modals/move-detail/move-detail.modal';
import { MoveLeaverDetailModalComponent } from '../modals/move-leaver-detail/move-leaver-detail.modal';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';
import { RealEstateAgentModalsService } from './real-estate-agent-modals.service';

@Injectable({ providedIn: RealEstateAgentProvidersModule })
export class RealEstateAgentDetailModalsService {
    constructor(
        private modalSandbox: ModalSandbox,
        private moveSandbox: MoveSandbox,
        private realEstateAgentModalService: RealEstateAgentModalsService
    ) {}

    public openMoverDetail(id: string, modalData = {}, callback?: () => void): void {
        this.moveSandbox.setStateFor(id).subscribe((move: Move) => {
            if (MoveUtils.isLeaver(move)) this.openLeaverDetail(id, modalData, callback);
            else this.openTransfereeDetail(id, modalData, callback);
        });
    }

    public openTransfereeDetail(id: string, modalData = {}, callback?: () => void): void {
        this.moveSandbox.setStateFor(id).subscribe(() => {
            const data = {
                id,
                ...modalData,
            };

            this.modalSandbox.openModal(
                MobileMoveOverviewComponent,
                { data },
                this.handleModalCloseTransferee(callback),
                MoveDetailModalComponent,
                { data },
                this.handleModalCloseTransferee(callback)
            );
        });
    }

    private handleModalCloseTransferee(callback: () => void) {
        return (response: ModalClosingData): void => {
            if (callback) callback();
            if (!response?.navigatedAway) this.moveSandbox.clearMoveState();
            if (response?.openMove) this.openLeaverDetail(response.openMove);
        };
    }

    public openLeaverDetail(id: string, modalData = {}, callback?: () => void): void {
        this.moveSandbox.setStateFor(id).subscribe(() => {
            const data = {
                id,
                ...modalData,
            };
            this.modalSandbox.openModal(
                MobileMoveLeaverOverviewComponent,
                { data },
                this.handleModalCloseLeaver(callback),
                MoveLeaverDetailModalComponent,
                { data },
                this.handleModalCloseLeaver(callback)
            );
        });
    }

    private handleModalCloseLeaver(callback: () => void) {
        return (response: ModalClosingData): void => {
            if (callback) callback();
            if (!response?.navigatedAway) this.moveSandbox.clearMoveState();
            if (response?.openMove) this.openTransfereeDetail(response.openMove, { options: { activeTab: response.activeTab } });
        };
    }

    public openInsurancesModal(data: ModalData, closeCallback: (_?: any) => void): void {
        this.moveSandbox.setStateFor(data.id).subscribe(() => {
            this.modalSandbox.openModal(
                MobileMoveDetailInsurancesModalComponent,
                { data },
                this.realEstateAgentModalService.onCloseClearMoveState(closeCallback),
                MoveDetailModalComponent,
                {
                    data: { ...data, options: { activeTab: Service.insurances } },
                },
                this.handleModalCloseTransferee(closeCallback)
            );
        });
    }
}
