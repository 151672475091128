import { AbstractControl, FormGroup } from '@angular/forms';
import { findKey } from 'lodash';
import { ErrorState } from './error-state/error-state.interface';

export class FormUtils {
    /**
     * Returns translation key
     */
    public static getFirstError(control: AbstractControl): ErrorState {
        if (!control) {
            return null;
        }
        const errors = control.errors;
        const firstError = findKey(control.errors, (error) => !!error);
        if (!firstError) {
            return null;
        }
        return {
            key: firstError,
            value: errors[firstError] as object,
        };
    }

    public static getTouchedValues(form: FormGroup): any {
        const changedValues: any = {};
        Object.keys(form.controls).forEach((key) => {
            const control = form.get(key);
            if (control?.dirty) {
                changedValues[key] = control.value;
            }
        });
        return changedValues;
    }
}
