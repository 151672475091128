import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumUtils, SimpleChangesUtils, StringUtils } from '@smooved/core';
import { SvgIllustration } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';
import { EnergyMetersState, WaterMeterState } from '@smooved/core';

@Component({
    selector: 'smvd-ui-meter-state-pill-base',
    templateUrl: 'meter-state-pill-base.component.html',
    styleUrls: ['meter-state-pill-base.component.scss'],
})
export class MeterStatePillBaseComponent implements OnInit, OnChanges {
    @Input() public meterState: EnergyMetersState | WaterMeterState = EnergyMetersState.Incomplete;
    @Input() public todo: boolean = false;

    public stateKey: string;
    public stateClass = `__meter-state--${EnergyMetersState.Incomplete}`;
    public svgIcon$ = new BehaviorSubject<string>(null);

    public ngOnInit(): void {
        this.setAppearanceAndState();
    }

    public ngOnChanges({ meterState }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(meterState)) {
            this.setAppearanceAndState();
        }
    }

    protected setAppearanceAndState(): void {
        this.setSvgIcon();
    }

    protected setSvgIcon(): void {
        if (EnumUtils.values(EnergyMetersState).includes(this.meterState) || EnumUtils.values(WaterMeterState).includes(this.meterState)) {
            this.svgIcon$.next(SvgIllustration[`MeterState${StringUtils.capitalize(this.stateKey)}`]);
        } else {
            this.svgIcon$.next(null);
        }
    }
}
