<app-closable-modal-template maxWidth="650px">
    <ng-container modal-header>
        <h1 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-20">
            {{ 'MOVE.SERVICE_ACTIONS.WATER.ADD_EXTRA_DATA.TITLE' | translate }}
        </h1>
        <p class="u-font-size-default u-color-gray-dark">{{ (moveSandbox.move$ | async)?.user?.formattedMovingAddress }}</p>
    </ng-container>

    <app-loading-container [loading]="!(moveSandbox.move$ | async) || loading">
        <app-alert
            *ngIf="waterTransferLocked$ | async"
            [context]="uiContext.Success"
            icon="check_circle"
            [iconContext]="uiContext.Success"
            class="u-w100p u-margin-bottom-xs u-margin-top-xs"
        >
            <h6 class="u-margin-bottom-xs">{{ 'ENERGY.ENERGY_METER_INFO.PROCESSED.INFO.TITLE' | translate }}</h6>
            <p
                [innerHTML]="
                    'ENERGY.ENERGY_METER_INFO.PROCESSED.INFO.BODY'
                        | translate: { href: moveSandbox.meterInfoTransfereeContactUsLink$ | async }
                "
            ></p>
        </app-alert>

        <h2 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-24 u-margin-bottom u-margin-top">
            {{ 'MOVE.SERVICE_ACTIONS.WATER.GENERAL_INFO' | translate }}
        </h2>

        <div class="u-flex u-flex-column">
            <mat-radio-button
                id="transfer-via-smooved"
                [checked]="!(hasCollectiveWaterMeter$ | async)"
                [disabled]="waterTransferLocked$ | async"
                (change)="toggleWaterTransferNeeded(true)"
            >
                {{ 'MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.TITLE' | translate }}
            </mat-radio-button>

            <mat-radio-button
                id="no-transfer"
                class="u-margin-top u-margin-bottom"
                [checked]="hasCollectiveWaterMeter$ | async"
                [disabled]="waterTransferLocked$ | async"
                (change)="toggleWaterTransferNeeded(false)"
            >
                {{ 'MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.TITLE' | translate }}
            </mat-radio-button>
        </div>
        <div class="u-flex-column u-color-gray-ultradark" *ngIf="!(hasCollectiveWaterMeter$ | async); else noWaterTransferCommunication">
            <div [formGroup]="form" class="u-flex-column u-flex-align-items-end" *ngIf="{ isMobile: isMobile$ | async } as ui">
                <div class="u-flex-column u-w100p">
                    <h3 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-20 u-margin-bottom-half u-margin-top">
                        {{ 'MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DATE.LABEL' | translate }}
                    </h3>

                    <p class="u-color-gray-dark u-margin-bottom">{{ 'MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DATE.INFO' | translate }}</p>

                    <app-date-input
                        class="u-margin-bottom"
                        width="auto"
                        [class.u-w50p]="!ui.isMobile"
                        [class.u-w100p]="ui.isMobile"
                        [formControlName]="waterDetailFields.MovingDate"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                        [required]="true"
                        [showRequiredAsterisk]="true"
                        [label]="'MOVE.SERVICE_ACTIONS.WATER.TRANSFER_DATE.LABEL' | translate"
                    ></app-date-input>

                    <h3 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-20 u-margin-bottom-half u-margin-top">
                        {{ 'MOVE.SERVICE_ACTIONS.WATER.TRANSFEREE_INFO.LABEL' | translate }}
                    </h3>

                    <p class="u-color-gray-dark">{{ 'MOVE.SERVICE_ACTIONS.WATER.TRANSFEREE_INFO.INFO' | translate }}</p>

                    <smvd-ui-text-input
                        width="auto"
                        class="u-margin-top"
                        [class.u-w50p]="!ui.isMobile"
                        [class.u-w100p]="ui.isMobile"
                        [formControlName]="waterDetailFields.NationalRegistrationNumber"
                        [placeholder]="placeholders[waterDetailFields.NationalRegistrationNumber]"
                        [labelTemplateRef]="nrnLabelTemplate"
                    ></smvd-ui-text-input>

                    <smvd-ui-text-input
                        id="client-number"
                        width="auto"
                        class="u-margin-bottom-none"
                        [class.u-w50p]="!ui.isMobile"
                        [class.u-w100p]="ui.isMobile"
                        [formControlName]="waterDetailFields.ClientNumber"
                        [placeholder]="placeholders[waterDetailFields.ClientNumber]"
                        [labelTemplateRef]="clientNumberLabelTemplate"
                    ></smvd-ui-text-input>

                    <app-separator></app-separator>

                    <h2 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-24 u-margin-bottom-half">
                        {{ 'MOVE.SERVICE_ACTIONS.WATER.METER_INFO.LABEL' | translate }}
                    </h2>

                    <p class="u-color-gray-dark">{{ 'MOVE.SERVICE_ACTIONS.WATER.METER_INFO.INFO' | translate }}</p>

                    <div class="u-background-gray-ultralight u-border-radius-large u-padding u-margin-top">
                        <div class="u-flex-row u-flex-justify-content-flex-start u-flex-align-items-center u-margin-bottom">
                            <smvd-ui-svg-illustration
                                [svg]="svgIllustration.MetersWater"
                                class="u-icon-24 u-margin-right-half u-color-gray-dark"
                            ></smvd-ui-svg-illustration>
                            <h3 class="u-font-weight-semi-bold u-color-gray-ultradark u-font-size-20">{{ 'WATER.LABEL' | translate }}</h3>
                        </div>

                        <smvd-ui-text-input
                            class="u-flex-grow-1"
                            [formControlName]="waterDetailFields.MeterNumber"
                            [placeholder]="placeholders[waterDetailFields.MeterNumber]"
                            [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_NUMBER' | translate"
                        ></smvd-ui-text-input>
                        <app-meter-reading-input
                            class="u-flex-grow-1 u-w100p"
                            [formControlName]="waterDetailFields.MeterReading"
                            [placeholder]="placeholders[waterDetailFields.MeterReading]"
                            ngDefaultControl
                            [label]="'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.METER_READING' | translate"
                            [hasMargin]="false"
                            [hasMarginDouble]="false"
                        >
                        </app-meter-reading-input>
                    </div>

                    <div class="__meter-info-divider-container u-flex u-flex-row u-flex-align-items-center">
                        <div class="__meter-info-divider-container-line"></div>
                        <span class="u-font-weight-semi-bold u-font-size-12 u-color-gray-dark u-padding-x-axis-half">{{
                            'COMMON.OR' | uppercase | translate
                        }}</span>
                        <div class="__meter-info-divider-container-line"></div>
                    </div>

                    <div class="u-w100p u-padding u-border-radius u-background-gray-ultralight">
                        <div class="u-flex u-flex-align-items-center">
                            <app-svg-illustration [svg]="svgIllustration.File" class="u-margin-right-xs"></app-svg-illustration>
                            <h3 class="u-margin-bottom-xs u-font-size-20 u-color-gray-ultradark">{{ 'ATTACHMENTS' | translate }}</h3>
                        </div>
                        <div class="u-color-gray-dark u-margin-bottom">{{ 'ENERGY.METER_READING.ATTACHMENTS.INFO' | translate }}</div>

                        <div
                            *ngFor="let item of uploader.queue"
                            class="__energy-meters-info-modal-container-attachments u-flex u-flex-align-items-center u-flex-justify-content-space-between u-margin-bottom-half"
                        >
                            <div class="u-link-inverted u-flex u-flex-align-items-center" (click)="openFileDetail(item)">
                                <app-svg-illustration
                                    class="__energy-meters-info-modal-container-attachments-file-icon u-margin-right-xs"
                                    [svg]="svgIllustration.File"
                                ></app-svg-illustration>
                                <span class="__energy-meters-info-modal-container-attachments-file-name u-text-truncate">
                                    {{ item?.file?.name }}
                                </span>
                            </div>
                            <a
                                app-svg-illustration
                                class="u-color-gray-dark u-cursor-pointer"
                                [svg]="svgIllustration.CloseButton"
                                (click)="removeAsset(item)"
                                *ngIf="!(waterTransferLocked$ | async)"
                            ></a>
                        </div>
                        <button
                            [appearance]="buttonAppearance.Stroked"
                            [context]="uiContext.Muted"
                            [size]="buttonSize.Default"
                            class="u-font-weight-semi-bold u-margin-top-half"
                            id="add-attachments-disabled-button"
                            disabled
                            smvd-ui-button
                            *ngIf="waterTransferLocked$ | async; else canSaveAttachments"
                        >
                            {{ 'ENERGY.METER_READING.ATTACHMENTS.CTA' | translate }}
                        </button>
                        <ng-template #canSaveAttachments>
                            <button
                                [appearance]="buttonAppearance.Stroked"
                                [context]="uiContext.PrimaryDark"
                                [size]="buttonSize.Default"
                                class="u-font-weight-semi-bold u-margin-top-half"
                                (click)="fileInput.click()"
                                id="add-attachments-enabled-button"
                                smvd-ui-button
                            >
                                {{ 'ENERGY.METER_READING.ATTACHMENTS.CTA' | translate }}
                            </button>
                        </ng-template>
                        <input
                            #fileInput
                            hidden
                            [accept]="ALLOWED_MIME_TYPES"
                            type="file"
                            ng2FileSelect
                            (onFileSelected)="onFileSelected($event)"
                            [uploader]="uploader"
                            multiple
                        />
                        <div class="u-color-gray-dark u-margin-bottom u-font-size-12 u-margin-top-xs">
                            {{ 'ENERGY.METER_READING.ATTACHMENTS.FILES_SUPPORT' | translate }}
                        </div>
                    </div>
                </div>

                <div class="u-text-align-right u-w100p u-margin-top-triple" *ngIf="!(waterTransferLocked$ | async)">
                    <ng-container *ngIf="canConfirmMeters$ | async; else canSave">
                        <button
                            smvd-ui-button
                            [disabled]="form.pristine"
                            [appearance]="buttonAppearance.Stroked"
                            [context]="uiContext.PrimaryDark"
                            class="__button-save"
                            type="submit"
                            (click)="saveWaterData()"
                        >
                            {{ 'SAVE_ONLY' | translate }}
                        </button>
                        <button
                            id="confirm-button"
                            smvd-ui-button
                            [context]="uiContext.PrimaryDark"
                            class="__button-save u-margin-left-half"
                            (click)="confirmAndSaveWaterData()"
                        >
                            {{ 'SAVE_AND_CONFIRM' | translate }}
                        </button>
                    </ng-container>

                    <ng-template #canSave>
                        <button
                            id="submit-button"
                            smvd-ui-button
                            [disabled]="form.pristine"
                            [context]="uiContext.PrimaryDark"
                            class="__button-save"
                            (click)="saveWaterData()"
                        >
                            {{ 'SAVE' | translate }}
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>
    </app-loading-container>
</app-closable-modal-template>

<ng-template #nrnLabelTemplate>
    <div class="u-margin-bottom-half">
        <span>{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.NATIONAL_REGISTRATION_NUMBER' | translate }}</span>
        <app-icon
            icon="info"
            class="u-display-inline-block u-margin-left-half"
            appTooltip
            [paddingSize]="uiSize.Xs"
            [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-half __tooltip'"
            [alignment]="UiAlignment.Left"
            [template]="nrnTooltip"
        ></app-icon>
    </div>

    <ng-template #nrnTooltip> {{ 'MOVE.SERVICE_ACTIONS.WATER.NATIONAL_REGISTRATION_NUMBER.TOOLTIP' | translate }} </ng-template>
</ng-template>

<ng-template #clientNumberLabelTemplate>
    <div class="u-margin-bottom-half">
        <span>{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.CLIENT_NUMBER' | translate }}</span>
        <app-icon
            icon="info"
            class="u-display-inline-block u-margin-left-half"
            appTooltip
            [paddingSize]="uiSize.Xs"
            [tooltipClasses]="'u-font-weight-normal u-line-height-0 u-font-size-small u-padding-half __tooltip'"
            [alignment]="UiAlignment.Left"
            [template]="clientNumberTooltip"
        ></app-icon>
    </div>

    <ng-template #clientNumberTooltip> {{ 'MOVE.SERVICE_ACTIONS.WATER.CLIENT_NUMBER.TOOLTIP' | translate }} </ng-template>
</ng-template>

<ng-template #noWaterTransferCommunication>
    <p class="u-font-size-12 u-color-muted">{{ 'MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.INFO' | translate }}</p>
</ng-template>
