<app-loading-container [loading]="loading" *ngIf="move$ | async as move">
    <ng-container
        *ngIf="{ waterTransferLocked: waterTransferLocked$ | async, hasCollectiveWaterMeter: hasCollectiveWaterMeter$ | async } as vm"
    >
        <app-alert
            id="locked-info"
            [context]="uiContext.Muted"
            [icon]="uiIcon.Info"
            class="u-margin-bottom-double"
            *ngIf="vm.waterTransferLocked"
        >
            <p>{{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.INFO_1" | translate }}</p>
            <p>
                {{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.INFO_2" | translate }}
                <a [href]="mailTo | uriSchema: uriSchemaType.MailTo">{{ "MOVE.SERVICE_ACTIONS.WATER.LOCKED.CTA" | translate }}</a>
            </p>
        </app-alert>

        <div class="u-flex u-flex-column">
            <mat-radio-button
                id="transfer-via-smooved"
                [checked]="!vm.hasCollectiveWaterMeter"
                [disabled]="vm.waterTransferLocked"
                (change)="toggleWaterTransferNeeded(true)"
            >
                {{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.TITLE" | translate }}
            </mat-radio-button>

            <mat-radio-button
                id="no-transfer"
                class="u-margin-top u-margin-bottom"
                [checked]="vm.hasCollectiveWaterMeter"
                [disabled]="vm.waterTransferLocked"
                (change)="toggleWaterTransferNeeded(false)"
            >
                {{ "MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.TITLE" | translate }}
            </mat-radio-button>
        </div>

        <div class="u-flex-column u-color-gray-ultradark" *ngIf="!(hasCollectiveWaterMeter$ | async); else noWaterTransferCommunication">
            <p class="u-font-size-12 u-margin-bottom-half">{{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.INFO_1" | translate }}</p>
            <p class="u-font-size-12">{{ "MOVE.SERVICE_ACTIONS.WATER.TRANSFER_VIA_SMOOVED.INFO_2" | translate }}</p>

            <app-separator></app-separator>

            <app-todo-list [label]="'WATER.DOCUMENTS.TITLE' | translate">
                <ng-container todo-list-header-right>
                    <smvd-ui-water-meter-state-pill
                        [meterState]="move?.track?.waterMeterReadings?.waterMetersState"
                        [todo]="moveUtils.checkForTodo(move, TodoType.waterDocumentsMovingAddressByRealEstateAgent)"
                    ></smvd-ui-water-meter-state-pill>
                </ng-container>
                <ng-container todo-list-body>
                    <ng-container [ngSwitch]="move?.track?.waterMeterReadings?.waterMetersState">
                        <ng-container *ngSwitchCase="waterMeterState.Incomplete" [ngTemplateOutlet]="waterMeterInfoBodyIncomplete">
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="waterMeterState.Completed"
                            [ngTemplateOutlet]="waterMeterInfoBodyCompleted"
                        ></ng-container>
                        <ng-container
                            *ngSwitchCase="waterMeterState.Confirmed"
                            [ngTemplateOutlet]="waterMeterInfoBodyConfirmed"
                        ></ng-container>
                        <ng-container
                            *ngSwitchCase="waterMeterState.Processed"
                            [ngTemplateOutlet]="waterMeterInfoBodyProcessed"
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </app-todo-list>

            <ng-template #waterMeterInfoBodyIncomplete>
                <p class="u-color-gray-dark u-margin-bottom-md">
                    {{ 'DETAIL.WATER.TRANSFER.INCOMPLETE.LABEL' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </p>
                <button
                    (click)="openWaterDataModal()"
                    [appearance]="buttonAppearance.Default"
                    [context]="uiContext.PrimaryDark"
                    id="cta-incomplete-open-meter-info"
                    class="u-font-weight-semi-bold"
                    smvd-ui-button
                >
                    {{ 'DETAIL.WATER.TRANSFER.INCOMPLETE.CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </button>
            </ng-template>

            <ng-template #waterMeterInfoBodyCompleted>
                <p class="u-color-gray-dark u-margin-bottom-md">
                    {{ 'DETAIL.WATER.TRANSFER.COMPLETED.LABEL' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </p>
                <button
                    (click)="openConfirmWaterModal()"
                    [appearance]="buttonAppearance.Default"
                    [context]="uiContext.PrimaryDark"
                    id="cta-completed-confirm"
                    class="u-font-weight-semi-bold"
                    smvd-ui-button
                >
                    {{ 'DETAIL.WATER.TRANSFER.COMPLETED.CONFIRM_CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </button>
                <button
                    (click)="openWaterDataModal()"
                    [appearance]="buttonAppearance.Default"
                    [context]="uiContext.Alt"
                    id="cta-completed-open-meter-info"
                    class="u-margin-left u-font-weight-semi-bold"
                    smvd-ui-button
                >
                    {{ 'DETAIL.WATER.TRANSFER.COMPLETED.VIEW_CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </button>
            </ng-template>

            <ng-template #waterMeterInfoBodyConfirmed>
                <p class="u-color-gray-dark u-margin-bottom-md">
                    {{ 'DETAIL.WATER.TRANSFER.CONFIRMED.LABEL' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </p>
                <button
                    (click)="openWaterDataModal()"
                    [appearance]="buttonAppearance.Default"
                    [context]="uiContext.Alt"
                    id="cta-confirmed-open-meter-info"
                    class="u-font-weight-semi-bold"
                    smvd-ui-button
                >
                    {{ 'DETAIL.WATER.TRANSFER.CONFIRMED.CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </button>
            </ng-template>

            <ng-template #waterMeterInfoBodyProcessed>
                <p class="u-color-gray-dark u-margin-bottom-md">
                    {{ 'DETAIL.WATER.TRANSFER.PROCESSED.LABEL' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </p>
                <button
                    (click)="openWaterDataModal()"
                    [appearance]="buttonAppearance.Default"
                    [context]="uiContext.Alt"
                    id="cta-processed-open-meter-info"
                    class="u-font-weight-semi-bold"
                    smvd-ui-button
                >
                    {{ 'DETAIL.WATER.TRANSFER.PROCESSED.CTA' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard }}
                </button>
            </ng-template>

            <app-todo-list
                [label]="'FOLLOW_UP' | translate"
                [completed]="move.track.waterMeterReadings.waterMetersState === waterMeterState.Processed"
                [subIfComplete]="move.water?.waterTransferProcessedByAdminOn | formatDate"
                class="u-margin-top"
            >
                <app-todo
                    id="rea-todo"
                    class="u-display-block u-margin-top"
                    [state]="TodoState.Disabled"
                    [checked]="move.track.waterMeterReadings.waterMetersState >= waterMeterState.Confirmed"
                    [label]="'DETAIL.WATER_TRANSFER.FOLLOW_UP.CONFIRMED_BY_BROKER' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                ></app-todo>
                <app-todo
                    id="admin-todo"
                    class="u-display-block"
                    [state]="TodoState.Disabled"
                    [checked]="move.track.waterMeterReadings.waterMetersState === waterMeterState.Processed"
                    [label]="'DETAIL.WATER_TRANSFER.FOLLOW_UP.PROCESSED_BY_SMOOVED' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard"
                ></app-todo>
            </app-todo-list>
        </div>
    </ng-container>
</app-loading-container>

<ng-template #noWaterTransferCommunication>
    <p class="u-font-size-12 u-color-muted">{{ "MOVE.SERVICE_ACTIONS.WATER.NO_TRANSFER.INFO" | translate }}</p>
</ng-template>
