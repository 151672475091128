import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WaterDetailsModalComponent } from '@app/admin/modals/water-details/water-details.modal';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { AddressUtils, WaterMeterState } from '@smooved/core';
import { ModalSandbox, UiContext, UiSize } from '@smooved/ui';

@Component({
    selector: 'smvd-app-water-meter-readings-confirmed',
    templateUrl: 'water-meter-readings-confirmed.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterMeterReadingsConfirmedComponent extends BaseServiceActionComponent {
    @Input()
    public move: Move;

    @Output() public moveUpdated: EventEmitter<Move> = new EventEmitter<Move>();

    public readonly uiContext = UiContext;
    public readonly appI18nKeyType = AppI18nKeyType;
    public isMetersCompleted = this.move?.track?.waterMeterReadings.waterMetersState > WaterMeterState.Completed;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        super(cdr, moveSandbox);
    }

    public openMeterReadings(): void {
        const input = { move: this.move };
        const header = this.translateService.instant('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.LABEL') as string;
        this.modalSandbox
            .openClosableModal({
                component: WaterDetailsModalComponent,
                config: {
                    input,
                    header,
                    subheader: AddressUtils.buildFormattedAddress(this.move.user.movingAddress),
                    size: UiSize.Md,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.moveUpdated.emit(move);
            });
    }

    protected readonly WaterMeterState = WaterMeterState;
}
