import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TodoType } from '@app/move/enums/todo-type.enum';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { MoverRole } from '@app/real-estate-agent/enums/mover-role.enum';
import { MoveDetailWaterDataModal } from '@app/real-estate-agent/modals/move-detail-water-data/move-detail-water-data-modal.component';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsEventsEnum, AnalyticsService, ArrayUtils, DbUtils, RxjsComponent, WaterMeterState } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, Svg, SvgIllustration, TodoState, UiContext, UiIcon, UriSchemaType } from '@smooved/ui';
import { of } from 'rxjs';
import { map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MoveDetailWaterSectionService } from './move-detail-water-section.service';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';
import { Water } from '@app/water/interfaces/water';

@Component({
    selector: 'app-move-detail-water-section',
    templateUrl: 'move-detail-water-section.html',
    styleUrls: ['move-detail-water-section.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MoveDetailWaterSection extends RxjsComponent implements OnInit {
    protected readonly TodoType = TodoType;
    protected readonly todoState = TodoState;
    protected readonly moverRole = MoverRole;
    protected readonly waterMeterState = WaterMeterState;

    @ViewChild('uploadInput', { static: true })
    public input: ElementRef;

    @Output() public moveUpdate = new EventEmitter();

    public move$ = this.moveSandbox.move$;
    public hasCollectiveWaterMeter$ = this.move$.pipe(map((move) => move.waterCollectiveMeter === true));
    public waterTransferLocked$ = this.move$.pipe(
        map((move: Move) => {
            return move?.waterDocumentsMovingAddressByAdmin;
        })
    );

    public loading = false;
    public svg = Svg;
    public readonly uiContext = UiContext;
    public readonly uiIcon = UiIcon;
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly svgIllustration = SvgIllustration;
    public readonly ArrayUtils = ArrayUtils;
    public readonly svgs = SvgIllustration;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly uriSchemaType = UriSchemaType;
    public readonly mailTo = environment.mailTo[this.translate.currentLang] as string;
    protected readonly moveUtils = MoveUtils;

    public innerModel: string;

    constructor(
        public moveSandbox: MoveSandbox,
        private modalSandbox: ModalSandbox,
        private readonly translate: TranslateService,
        private readonly analyticsService: AnalyticsService,
        private readonly moveDetailWaterSectionService: MoveDetailWaterSectionService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveDetailWaterSectionService.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => (this.loading = loading));
    }

    public toggleWaterTransferNeeded(value: boolean): void {
        this.loading = true;
        this.moveSandbox.patchProperty('waterCollectiveMeter', !value, true, (move) => {
            this.moveSandbox.setLatestMoveState(move);
            this.loading = false;
        });
    }

    public openWaterDataModal(): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            this.modalSandbox.openModal(
                MoveDetailWaterDataModal,
                {},
                () => {
                    this.moveUpdate.emit();
                },
                MoveDetailWaterDataModal,
                null,
                () => {
                    this.moveUpdate.emit();
                }
            );
            this.analyticsService.sendEvent(AnalyticsEventsEnum.ReaWaterAddExtraDataOpen, {
                moveId: DbUtils.getStringId(move),
            });
        });
    }

    public openConfirmWaterModal(): void {
        this.moveDetailWaterSectionService
            .confirmModal()
            .pipe(
                withLatestFrom(this.moveSandbox.moveOnce$),
                mergeMap(([confirm, move]) => {
                    if (confirm && !!move.water?.waterSupplier) {
                        return this.confirmFn();
                    } else if (confirm && !move.water?.waterSupplier) {
                        this.moveDetailWaterSectionService.openPickWaterSupplierModal(this.onPickWaterSupplier(move));
                    }
                    return of(null);
                })
            )
            .subscribe();
    }

    private confirmFn = () =>
        this.moveSandbox.idOnce$.pipe(
            mergeMap((moveId) => this.moveDetailWaterSectionService.confirmHandler(moveId)),
            tap((move) => {
                if (move) {
                    this.moveSandbox.setMoveState(move);
                    this.moveUpdate.emit();
                }
            })
        );

    private onPickWaterSupplier(move: Move) {
        return (waterSupplier: WaterSupplier): void => {
            this.moveSandbox.patch({
                moveId: DbUtils.getStringId(move),
                payload: { water: { waterSupplier: DbUtils.getStringId(waterSupplier) } as unknown as Water },
                withNotification: false,
                dialogIdToClose: null,
                callback: () => {
                    this.confirmFn().subscribe();
                },
            });
        };
    }

    protected readonly TodoState = TodoState;
}
