import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { ModalSandbox, UiContext } from '@smooved/ui';
import { WaterMeterState } from '@smooved/core';

@Component({
    selector: 'app-water-documents-moving-address-by-admin',
    templateUrl: 'water-documents-moving-address-by-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterDocumentsMovingAddressByAdminComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef,
        private readonly modalSandbox: ModalSandbox,
        private readonly translateService: TranslateService
    ) {
        super(cdr, moveSandbox);
    }

    public readonly uiContext = UiContext;
    public readonly appI18nKeyType = AppI18nKeyType;

    public check(): void {
        const config = {
            header: this.translateService.instant(
                'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.CONFIRM_MODAL.TITLE'
            ) as string,
            question: this.translateService.instant(
                'ADMIN.DASHBOARD.DETAIL.WATER.DOCUMENTS_MOVING_ADDRESS_BY_ADMIN.CONFIRM_MODAL.TEXT'
            ) as string,
        };
        this.modalSandbox.openConfirmModal(config, this.confirmCheck, config, this.confirmCheck);
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            this.move.waterDocumentsMovingAddressByAdmin ? 'waterDocumentsMovingAddressByAdmin' : 'waterCollectiveMeter',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    private confirmCheck = (confirm: boolean): void => {
        if (!confirm) return;
        this.moveSandbox.patchProperty(
            'waterDocumentsMovingAddressByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    };
    protected readonly WaterMeterState = WaterMeterState;
}
