import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { WaterSupplier } from '@app/water/interfaces/water-supplier';
import { getWaterSuppliersAction } from '@app/water/state/water.actions';
import { getWaterSuppliers } from '@app/water/state/water.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { State } from '../../store/state';
import { WaterInfoResponse } from '../interfaces/water-info-response';
import { WaterService } from '../services/water.service';
import { Asset } from '@smooved/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class WaterSandbox {
    public waterSuppliers$: Observable<WaterSupplier[]> = this.store$.select(getWaterSuppliers);

    constructor(
        private store$: Store<State>,
        private waterService: WaterService,
        private sanitizer: DomSanitizer
    ) {}

    public getWaterInfoByZipCode(zipCode: string): Observable<WaterInfoResponse[]> {
        return this.waterService.getWaterInfoByZipCode(zipCode).pipe(
            map((x) => {
                return x.sort((a, b) => {
                    if (a.regionalMatch < b.regionalMatch) {
                        return -1;
                    }
                    if (a.regionalMatch > b.regionalMatch) {
                        return 1;
                    }
                    return 0;
                });
            })
        );
    }

    public getWaterSuppliers(): void {
        this.store$.dispatch(getWaterSuppliersAction());
    }

    public confirmTransaction(transactionId: string): Observable<Move> {
        return this.waterService.confirmWaterProcess(transactionId);
    }

    public getAsset(transactionId: string, assetKey: string): Observable<SafeResourceUrl> {
        const reader = new FileReader();
        return this.waterService.getWaterTransferAsset(transactionId, assetKey).pipe(
            mergeMap((response) => {
                return new Observable<SafeResourceUrl>((emit) => {
                    reader.onloadend = () => emit.next(this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string));
                    reader.readAsDataURL(response.body);
                });
            })
        );
    }
}
